<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
            <!-- Forgot Password v1 -->
            <b-card class="mb-0">
                <b-link class="display-logo brand-logo">
                    <logo class="margin-logo" />
                    <h2 class="brand-text text-primary title-kalam mt-1">
                        {{ title }}
                    </h2>
                </b-link>

                <b-card-title class="mb-1"> Forgot Password? 🔒 </b-card-title>
                <b-card-text class="mb-2">
                    Enter your email and we'll send you instructions to reset
                    your password
                </b-card-text>

                <!-- form -->
                <validation-observer ref="simpleRules">
                    <b-form
                        class="auth-forgot-password-form mt-2"
                        @submit.prevent="validationForm"
                    >
                        <!-- email -->
                        <b-form-group
                            label="Email"
                            label-for="forgot-password-email"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="Email"
                                rules="required|email"
                            >
                                <b-form-input
                                    id="forgot-password-email"
                                    v-model="userEmail"
                                    :state="errors.length > 0 ? false : null"
                                    name="forgot-password-email"
                                    placeholder="john@example.com"
                                />
                                <small class="text-danger">{{
                                    errors[0]
                                }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- submit button -->
                        <b-button variant="primary" block type="submit">
                            Send reset link
                        </b-button>
                    </b-form>
                </validation-observer>

                <b-card-text class="text-center mt-2">
                    <b-link :to="{ name: 'login' }">
                        <feather-icon icon="ChevronLeftIcon" /> Back to login
                    </b-link>
                </b-card-text>
            </b-card>
            <!-- /Forgot Password v1 -->
        </div>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate"
import {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
} from "bootstrap-vue"
import { required, email } from "@validations"
import Auth from "@/api/services/Auth.js"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import Logo from "@/components/Logo.vue"
import { $themeConfig } from "@themeConfig"
import * as Constants from "@/store/constants.js"

export default {
    components: {
        BCard,
        BLink,
        BCardText,
        BCardTitle,
        BFormGroup,
        BFormInput,
        BButton,
        BForm,
        ValidationProvider,
        ValidationObserver,
        Logo,
    },
    data() {
        return {
            userEmail: "",
            // validation
            required,
            email,
            title: "",
        }
    },
    created() {
        this.title = $themeConfig.app.appLogoDescription.title
    },
    methods: {
        validationForm() {
            this.$refs.simpleRules.validate().then((success) => {
                if (success) {
                    this.resetPassword()
                }
            })
        },
        resetPassword() {
            this.$loading(true)
            Auth.resetPassword({
                email: this.userEmail,
                url: Constants.URL,
            })
                .then((response) => {
                    this.$loading(false)
                    this.$toast(
                        {
                            component: ToastificationContent,
                            props: {
                                title: "Check your email",
                                icon: "CheckIcon",
                                text: "To get back your account, follow the instructions we've sent your email.",
                                variant: "success",
                            },
                        },
                        {
                            position: "bottom-center",
                        }
                    )
                })
                .catch((error) => {
                    this.$loading(false)
                    this.$toast(
                        {
                            component: ToastificationContent,
                            props: {
                                title: "Error",
                                icon: "AlertTriangleIcon",
                                text: "Sorry, something went wrong there. Please try again.",
                                variant: "danger",
                            },
                        },
                        {
                            position: "bottom-center",
                        }
                    )
                })
        },
    },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
